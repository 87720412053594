import React from 'react'
import RouteConfig from 'routes'
function App() {
  return (
    <div className="App">
      <RouteConfig />
    </div>
  )
}

export default App
