import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import NavMenu from './NavMenu'
import UserMenu from './UserMenu'

export default function Header() {
  return (
    <div className="Header header-container">
      <div className="header-content">
        <div className="left-menu">
          <Link to={'/'} className="brand-link">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <NavMenu />

        <UserMenu />
      </div>
    </div>
  )
}
