import React from 'react'
import Header from 'components/Header'

export default function DefaultLayout({ children }: any) {
  return (
    <div className="DefaultLayout">
      <Header />
      {children}
    </div>
  )
}
