import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from 'pages/home'
import WelcomePage from 'pages/welcome'
import SignInPage from 'pages/sign-in'

export interface RouteWithSubRoutesProps {
  path: string
  exact?: boolean
  component: React.ComponentType<any>
  routes?: RouteWithSubRoutesProps[]
}

const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/welcome',
    component: WelcomePage,
  },
  {
    path: '/sign-in',
    component: SignInPage,
  },
]

export default function RouteConfig() {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  )
}

function RouteWithSubRoutes(route: RouteWithSubRoutesProps) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  )
}
